.rules {
    flex: 1;
    display: flex;
    flex-direction: column;
    color: var(--color-primary);
}

.rules__content {
    display: flex;
    flex: 1;
    flex-direction: column;
}

.rules__list {
    padding-top: var(--spacing-small);
}

.rule {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.rule__description {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: var(--spacing-tiny) 0 var(--spacing-tiny) var(--spacing-small);
    color: var(--color-title);
    font-size: var(--font-large);
}

.rule__description-domen {
    color: var(--color-important);
    font-size: var(--font-small);
}

.rule__description-time {
    color: var(--color-primary);
    font-size: var(--font-small);
    padding-top: var(--spacing-tiny);
}

.rule__actions {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.rule__add-new {
    display: flex;
    justify-content: center;
    padding-bottom: var(--spacing-small);
}
