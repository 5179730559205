.adapted_price {
    flex: 1;
    display: flex;
    flex-direction: column;
    color: var(--color-primary);
}

.adapted_price__title {
    display: flex;
    justify-content: center;
    color: var(--color-primary);
    padding: var(--spacing-medium) 0;
    font-size: var(--font-large);
    font-weight: var(--font-medium);
}

.adapted_price__price {
    display: flex;
    justify-content: center;
    color: var(--color-important);
    padding: var(--spacing-small) 0;
    font-size: var(--font-medium);
    font-weight: var(--font-medium);
}

.adapted_price__calculate-button {
    display: flex;
    justify-content: center;
    padding: var(--spacing-small) 0;
}
