.account {
    width: 360px;
    display: flex;
}

.account__content {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 0 var(--spacing-medium);
    border: var(--spacing-border) solid var(--color-background-light);
    background-color: var(--color-background);
}

.account__row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 var(--spacing-small);
}

.account__important {
    color: var(--color-important);
}
