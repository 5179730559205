.webTexts {
    width: 360px;
    min-height: var(--spacing-genormus);
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-top: var(--spacing-tiny);
}

.webTexts__content {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 0 var(--spacing-medium);
    border: var(--spacing-border) solid var(--color-background-light);
    background-color: var(--color-background);
}

.webTexts__entry {
    display: flex;
    flex-direction: column;
}

.webTexts__entry__web {
    display: flex;
    justify-content: space-between;
    font-weight: var(--font-bold);
    padding: var(--spacing-small) 0;
    color: var(--color-important);
}

.webTexts__entry__text {
    font-size: var(--font-small);
}

.webTexts__entry img {
    width: 20px;
}

.webTexts__important {
    color: var(--color-important);
}
