.socialMessages {
    min-height: var(--spacing-genormus);
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-top: var(--spacing-tiny);
}

.socialMessages__entry {
    display: flex;
    flex-direction: column;
}

.socialMessages__entry__account {
    display: flex;
    justify-content: space-between;
    font-weight: var(--font-bold);
}

.socialMessages__entry__text {
    font-size: var(--font-small);
}

.socialMessages__entry img {
    width: 20px;
}
